import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
import valuesFlipBox from "../components/flipboxes/valuesFlipBox";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "gatsby";

//Images
import rsaFlag from "../images/rsa-flag.svg";
import productivity from "../images/arrows.svg";
import sustainable from "../images/plant.svg";
import ethics from "../images/clock.svg";
import relationships from "../images/shake.svg";
import bbbee from "../images/bbbee.png";
// import sustainImg from '../images/sustain.png';

import liezl from "../images/liezl.png";
import steved from "../images/steved.png";
import jonathan from "../images/jonathan.png";
import willie from "../images/willie.png";
import maziar from "../images/mazi.jpg";

import stsLogo from "../images/gatsby-icon.png";
import rdpLogo from "../images/rdpLogo.webp";
import rdpBlueprint from "../images/rdp-blueprint.png";
import rdpElement from "../images/rdp-element.png";
import adpLogo from "../images/adpLogo.webp";
import check from "../images/check.svg";
import technology from "../images/group/technology.png";

import squareElementHead from "../images/redblock2.svg";
import squareElement1 from "../images/square-element1.svg";

//CSS
import "../styles/index.scss";
import "../styles/rdp.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import SEO from "../components/seo";
import RubrikFlipBox from "../components/flipboxes/rubrikDataProtection";
import Card from "../components/Card";

import rdpi1 from "../images/rdpb1.png";
import rdpi2 from "../images/rdpb2.png";
import rdpi3 from "../images/rdpb3.png";

const DataProtection = () => {
  const props = {
    seoTitle: "Rubrik Data Protection",
    seoDescription:
      "Managed data backup, disaster recovery and business continuity to ensure your business is always online no matter what gets thrown your way.",
  };
  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* About Hero */}
        <section>
          <div className="hero" id="rdp">
            <div className="flex-col-c">
              <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                <h1 className="fade-intro">Data Protection</h1>
                <p
                  className="fade-intro"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span style={{ width: "60%" }}>
                    Managed data backup, disaster recovery and business
                    continuity to ensure your business is always online no
                    matter what gets thrown your way.{" "}
                  </span>
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* Partnering with ADP */}
        <section className="half" style={{ zIndex: "9" }} id="strategix-group">
          <div className="sect-wrap">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <div className="sts-partner-img">
                  <img alt="" src={stsLogo} className="image-fit" />
                  <img alt="" src={rdpLogo} className="image-fit" />
                  <img alt="" src={adpLogo} className="image-fit" />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp">
                <h2
                  className="fade-in-up"
                  style={{
                    zIndex: "9",
                    textAlign: "center",
                    color: "var(--red)",
                  }}
                >
                  Partnering with Assured Data Protection, our engineering
                  teams’ backup experience and expertise allows your IT teams to
                  focus on core operations, with the peace-of-mind that your IT
                  infrastructure is safeguarded against the toughest threats.
                </h2>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* Our Values */}
        <section id="values">
          <div className="values-wrapper">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                <h1 style={{ marginTop: "0" }} className="fade-in-up">
                  Our Range of Services
                </h1>
                <p style={{ textAlign: "center" }}>
                  Our services are offered in a flexible, pay-as-you-go model,
                  allowing you to protect business-critical data, applications
                  and servers, whether on-premises, hybrid or in the cloud.
                </p>
              </ScrollAnimation>
              <br />
              <br />
              <RubrikFlipBox />
            </div>
          </div>
        </section>

        {/* Culture */}
        <section id="ethos">
          <div className="sect-wrap">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{ marginTop: "0", textAlign: "center" }}
                className="fade-in-up"
              >
                Key benefits of Data Protection as a Service
              </h1>
            </ScrollAnimation>
            <div className="dp-benefits">
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Access proven data protection expertise</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Reduce Upfront and Ongoing Costs</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Reduce Data Backup and Recovery Times</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Eliminate Labour-Intensive Tasks</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Allow IT teams to focus on core operation</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Accelerate Your Journey to the Cloud</p>
              </div>
            </div>
          </div>
        </section>

        <div className="rdp-el">
          <img alt="" src={rdpElement} />
        </div>

        <section className="sect-wrap team-wrap" id="team">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1 style={{ marginTop: "0" }} className="fade-in-up">
                Rubrik’s Data Management Platform
              </h1>
              <p
                className="fade-in-up"
                style={{ textAlign: "center", fontWeight: "600" }}
              >
                Data management, simplified.
              </p>
              <p style={{ textAlign: "center" }}>
                Our data protection solutions are powered by Rubrik’s cloud data
                management product. Rubrik is recognized by Gartner as a Leader
                in the Magic Quadrant for Enterprise Backup and Recovery
                Software Solutions. Rubrik offers a single software platform
                that securely delivers backup, recovery, analytics, and
                compliance across data centers and clouds. The ProtectView
                management and reporting platform transforms data management and
                security, allowing for scalable and affordable practices.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <div className="team" style={{ alignItems: "center" }}>
              <img
                src={rdpBlueprint}
                alt="Strategix Partner Rubrik Data Protection Blueprint"
                style={{ width: "80%" }}
              />
            </div>
          </div>
        </section>

        <div className="contact-svg" />

        <section className="sect-wrap team-wrap" id="team">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1 style={{ marginTop: "0" }} className="fade-in-up">
                Recover Instantly From Ransomware
              </h1>
              <p style={{ textAlign: "center" }}>
                Keep your data safe from ransomware with Rubrik’s Zero Trust
                Data Security™. Protect your data against the myriad threats
                your organisation faces enhance your overall security posture.
                <br />
                <br />
                Rubrik integrates with security systems to seamlessly identify
                sensitive data and determine whether it was compromised during
                ransomware attacks. Locate impacted data and recover instantly,
                from impacted data to full workload recovery.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <div className="outer-container" id="rdp-card">
              <div className="row">
                {/******** Desktop Cards *******/}

                <div className="desktop-card4">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1}
                    delay={0}
                  >
                    <div className="inner-container-3">
                      <Card
                        icon={rdpi1}
                        heading="Full restore"
                        visibility="true"
                        info="Swiftly recover all data and 
                        workloads using Rubrik's 
                        mass recovery wizard."
                      />
                    </div>{" "}
                  </ScrollAnimation>
                </div>

                <div className="desktop-card4">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1}
                    delay={100}
                  >
                    <div className="inner-container-3">
                      <Card
                        icon={rdpi2}
                        heading="Impacted Data"
                        automation="true"
                        info="Eliminate unnecessary data 
                        loss by pinpointing and 
                        recovering only impacted data."
                      />
                    </div>
                  </ScrollAnimation>
                </div>

                <div className="desktop-card4">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={1}
                    delay={200}
                  >
                    <div className="inner-container-3">
                      <Card
                        icon={rdpi3}
                        heading="App Level"
                        application="true"
                        info="Recover groups of apps and 
                        their dependencies and restart 
                        priorities with ease."
                      />
                    </div>{" "}
                  </ScrollAnimation>
                </div>

                {/* Mobile Here */}
              </div>
            </div>
            <br />
            <br />
            <div className="dp-benefits2">
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>True immutability</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Sensitive data recovery</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Multi-factor authentication</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Retention lock</p>
              </div>
              <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Anomaly detection</p>
              </div>
              {/* <div className="dp-benefit">
                <img src={check} alt="" />
                <p>Accelerate Your Journey to the Cloud</p>
              </div> */}
            </div>
          </div>
        </section>

        {/* <PartnerCarousel /> */}
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "5rem",
          }}
        >
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <p
              style={{
                textAlign: "center",
                paddingBottom: "0",
                paddingTop: "50px",
              }}
            >
              Need help creating and implementing your data protection strategy?
            </p>
            <h1
              style={{
                textAlign: "center",
                margin: "0",
                paddingBottom: "20px",
              }}
            >
              Get in touch today
            </h1>
          </ScrollAnimation>

          <Link to="/contact">
            <button>
              Contact Us <span>&#x279C;</span>
            </button>
          </Link>
        </section>

        {/* Contact */}
        {/* <section className="half">
          <ContactForm />
        </section> */}
      </main>
    </Layout>
  );
};

export default DataProtection;
