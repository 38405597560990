import React from "react";

import "../../styles/card.scss";
//Icons
import ScrollAnimation from "react-animate-on-scroll";

import rdpi1 from "../../images/rdpi1.svg";
import rdpi2 from "../../images/rdpi2.svg";
import rdpi3 from "../../images/rdpi3.svg";
import rdpi4 from "../../images/rdpi4.svg";
import rdpi5 from "../../images/rdpi5.svg";
import rdpi6 from "../../images/rdpi6.svg";

import Card from "../Card";

export default function RubrikFlipBox() {
  return (
    <div className="outer-container" id="rdp-card">
      <div className="row">
        {/******** Desktop Cards *******/}

        <div className="desktop-card3">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1} delay={0}>
            <div className="inner-container-3">
              <Card
                icon={rdpi1}
                heading="Managed backup
                as a Service"
                visibility="true"
                info="Protect your
                business with
                enterprise-grade
                backup, recovery
                and archive suited
                for private, public
                and hybrid cloud
                environments."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        <div className="desktop-card3">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={100}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi2}
                heading="Disaster Recovery
                as a Service
                "
                automation="true"
                info="Bring your servers,
                applications and data
                back online following
                an IT system failure.
                Continue business 
                operations with
                minimal disruption."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card3">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={200}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi3}
                heading="Business Continuity
                as a Service
                "
                application="true"
                info="Recover your business 
                beyond the last
                backup – Business 
                Continuity as a Service
                allows you to recover
                your business up to
                the very second it
                goes offline."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        <div className="desktop-card3">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={300}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi4}
                heading="Rubrik Onsite
                Appliance
                "
                services="true"
                info="For secure data center
                and cloud operations,
                Rubrik’s onsite
                appliance allows for
                backup, instant
                recovery, replication,
                search, analytics,
                archival, compliance,
                and copy data
                management."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card3">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={300}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi5}
                heading="Cloud Backup
                for M365"
                services="true"
                info="Protect your
                business critical
                data from hackers,
                encryption malware
                and accidental
                deletion with
                military-grade
                backup."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card3">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={300}
          >
            <div className="inner-container-3">
              <Card
                icon={rdpi6}
                heading="Ransomware Protection"
                services="true"
                info="You don't have to pay the ransom! 
                Instantly recover from ransomware attacks with immutable backups from Rubrik."
              />
            </div>
          </ScrollAnimation>
        </div>

        {/* Mobile Here */}
      </div>
    </div>
  );
}
